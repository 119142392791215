import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./containers/ErrorBoundary";
import "typeface-metropolis/index.css";
import "./polyfills";
import "./index.css";
import App from "./containers/App";
import registerServiceWorker from "./registerServiceWorker";
import { injectCookieManagement } from "./injectCookieManagement";
import { initTelemetryService } from "./telemetryService";

ReactDOM.render((
    <BrowserRouter basename="/vsc">
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>
), document.getElementById("root"));

registerServiceWorker();
injectCookieManagement();
initTelemetryService();
