import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { IS_TELEMETRY_ENVIRONMENT_VALID } from "../../telemetryService";
 
const MatomoRouteTracker = () => {
    /**
     * Integration Guide: https://vmw-confluence.broadcom.net/display/LUMOS/Integrate+with+AppFx+Telemetry
     * Tracking route changes 
     */
    const location = useLocation();
    const previousUrl = useRef(location.pathname);
 
    useEffect(() => {
        if (IS_TELEMETRY_ENVIRONMENT_VALID && window._paq) {
            const referrerUrl = previousUrl.current;
            window._paq.push([
                "setReferrerUrl",
                referrerUrl
            ]); 
            window._paq.push([
                "setCustomUrl",
                location.pathname + location.search
            ]);
            window._paq.push([
                "setDocumentTitle",
                document.title
            ]);
            window._paq.push(["trackPageView"]);
 
            previousUrl.current = location.pathname + location.search;
        }
    }, [location]);
 
    return null;
};
 
export default MatomoRouteTracker;