import LocalStorageHelper from "./services/helpers/LocalStorageHelper";

const telemetry = require("@vmw/appfx-telemetry/telemetry");
const telemetryService = new telemetry.TelemetryService();
const ENV = process.env.REACT_APP_CONFIG_ENV;

export const IS_TELEMETRY_ENVIRONMENT_VALID = ENV === "production" || ENV === "staging";

export const initTelemetryService = () => {
    if(!IS_TELEMETRY_ENVIRONMENT_VALID){
        console.log("Skipping initialization for telemetry service in current environment.");
        return;
    }

    const isProduction = ENV === "production";
    telemetryService.init("cm_web_analytics", "content_management_ui", isProduction);

    const user = LocalStorageHelper.get("userId");
    if(user){
        telemetryService.setUsername(user);
    }
};

/**
 * Tracks a telemetry event by sending it to Super Collider (only if the feature is actively enabled).
 * @param eventCategory
 *    The category of the event, e.g. "login". Populated in the e_c column in Super Collider DB.
 *
 * @param eventAction
 *    The specific action, e.g. "submit". Populated in the e_a column in Super Collider DB.
 *
 * @param eventName
 *    Name of the event. E.g. "country". Populated in the e_n column in Super Collider DB.
 *
 * @param eventValue
 *    The value of the event. E.g. "Australia". Populated in the e_v column in the Super Collider DB.
 */
export const trackCustomTelemetryEvent = (eventCategory, eventAction, eventName, eventValue) => {
    if(!IS_TELEMETRY_ENVIRONMENT_VALID){
        return;
    }

    telemetryService.trackEvent(eventCategory, eventAction, eventName, eventValue);
};